<template>
    <main>
        <HeaderTab :title="$t('tabs.gestion_tabs')" :back_action="back_action"/>

        <div id="content">
            <div class="box">
                <div class="row">
                    <div class="col-12">
                        <label>{{ $t('tabs.type_tab') }}</label>
                        <e-select
                            :options="all_tabs_type"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="true"
                            :search="true"
                            v-model="tab_type_selected"
                            @select="switchTabType"
                        >
                            <template slot="singleLabel" slot-scope="props">
                                {{ $t('tabs.tabs.'+props.option) }}
                            </template>

                            <template slot="option" slot-scope="props">
                                {{ $t('tabs.tabs.'+props.option) }}
                            </template>
                        </e-select>
                    </div>
                </div>

                <div class="row mt-3">
                   <div class="col-6 pr-0 pr-md-3">
                        <h5>{{ $t('tabs.all_tabs') }}</h5>
                        <draggable class="list-group list-group-left" :list="tabs_dispo" v-bind="dragOptionsAllTab" group="colonne">
                            <div class="list-group-item  d-md-flex align-items-center " v-for="(tab, index) in tabs_dispo" :key="'dispo_col_'+index">
                                {{ getTabTrad(tab_type_selected, tab) }}
                                <div class="btn btn-secondary ml-auto" @click="select_tab(tab)">
                                    <font-awesome-icon :icon="['fal', 'long-arrow-right']" />
                                </div>
                            </div>
                        </draggable>
                    </div>
                    <div class="col-6 pl-0 pl-md-3">
                        <h5>{{ $t('tabs.tabs_selected') }}</h5>
                        <draggable class="list-group" :list="tabs_selected" v-bind="dragOptionsSelectedTab" group="colonne">
                            <div class="list-group-item d-md-flex align-items-center " v-for="(tab, index) in tabs_selected" :key="'select_col_'+index">
                                <div class="badge badge-pill badge-secondary mr-2">{{ index }}</div>
                                <div class="btn btn-secondary ml-auto order-md-last">
                                    <font-awesome-icon :icon="['fal', 'sort-alt']" />
                                </div><br class="d-md-none"/> {{ getTabTrad(tab_type_selected, tab) }}
                            </div>
                        </draggable>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="text-center mt-5">
                            <button @click="saveData" class="btn btn-primary rounded-pill mr-3">
                                {{ $t('global.sauvegarder') }}
                                <font-awesome-icon :pulse="is_saving" :icon="is_saving ? ['fa', 'spinner'] : ['fal', 'save']" class="ml-1"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script type="text/javascript">
import draggable from 'vuedraggable'
import Config from "@/mixins/Config.js"

export default {
    name: 'TabConfig',
    mixins: [Config],
    data () {
        return {
            all_tabs_type: [],
            all_tabs: [],
            tab_type_selected: null,
            tabs_dispo: [],
            tabs_selected: [],
            dragOptionsAllTab: {
                sort: false
            },
            dragOptionsSelectedTab: {
                animation: 200
            },
            is_saving: false,
            from: null
        }
    },

    mounted() {
        this.init_component()
    },

    methods: {
        async init_component() {
            this.all_tabs = this.getConfig('tabs_config')
            this.all_tabs_type = Object.keys(this.all_tabs)
            this.tab_type_selected = this.$route.params.type
            this.from = this.$route.params.from
            this.switchTabType(this.tab_type_selected)
        },

        switchTabType(key) {
            this.tabs_selected = this.all_tabs[key][1].length > 0 ? this.all_tabs[key][1][0].tabs : []
            const tabs_dispo = this.all_tabs[key][-1][0].tabs
            let index = null
            if(!this.$store.state.userAccess.hasPaxAccess && (key == 'mare' || key == 'stallion')) {
                index = tabs_dispo.indexOf('HorsePax');
                if (index > -1) {
                    tabs_dispo.splice(index, 1)
                }
            }
            if(!this.$store.state.userAccess.hasWebCommunicationAccess) {
                index = tabs_dispo.indexOf('HorseWebContent');
                if (index > -1) {
                    tabs_dispo.splice(index, 1)
                }
            }
            if(!this.$store.state.userAccess.hasAdminAccess) {
                index = tabs_dispo.indexOf('tiersAdmin');
                if (index > -1) {
                    tabs_dispo.splice(index, 1)
                }
            }
            this.tabs_dispo = tabs_dispo.filter(tab => !this.tabs_selected.includes(tab))

        },

        getTabTrad(tab_type, tab) {
            return this.getTrad('tabs.'+tab_type+'.'+tab)
        },

        select_tab(tab) {
            const index = this.tabs_dispo.indexOf(tab)
            this.tabs_dispo.splice(index, 1)
            this.tabs_selected.push(tab)
        },

        async saveData() {
            if(this.is_saving) return

            this.is_saving = true
            const result = await this.addTabConfig(this.tab_type_selected, this.tabs_selected)
            if(result && result.code_retour == 0 && result.retour) {
                this.successToast('toast.info_modif_succes')
                await this.setConfig('tabs_config', result.retour)
            }
            else {
                this.failureToast('toast.info_save_failed')
            }
            this.is_saving = false
        },

        back_action() {
            if(this.from) {
                this.$router.push({path: this.from})
            }
            else {
                window.history.back()
            }
        },
    },

    components: {
        HeaderTab: () => import("@/components/HeaderTab"),
        draggable
    }
}

</script>